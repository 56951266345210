import { useState } from "react";
import { API } from "aws-amplify";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function Subscribe() {
  const [email, setEmail] = useState("");
  const [, setClicked] = useState(false);
  let [isOpen, setIsOpen] = useState(false);

  const [subscribed, setSubscribed] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setIsOpen(true);
    if (email.length === 0) {
      setClicked(false);
      return;
    }
    if (email.length > 0) {
      const res = await API.post("votes", `/subscribe`, {
        body: {
          email: email,
        },
      });
      if (res.data.status === "success") {
        setIsOpen(false);
        setSubscribed(true);
      }
    }
  }

  let handleOnChange = (email) => {
    // don't remember from where i copied this code, but this works.
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(email)) {
      setEmail(email);
    } else {
      // invalid email, maybe show an error to the user.
    }
  };

  return (
    <>
      <div className="min-h-screen overflow-x-hidden">
        <div className="dakr:bg-slate-800 relative h-screen w-screen bg-white from-white to-transparent dark:from-blue-100 dark:to-blue-200">
          <img
            className="absolute inset-0 h-full w-full object-cover mix-blend-multiply brightness-50 filter"
            alt="main background"
            src="https://source.unsplash.com/random"
          />

          <div className="absolute inset-0 mx-auto flex w-5/6 max-w-lg flex-col items-center justify-center text-center ">
            {subscribed ? (
              <div className="rounded-lg dark:bg-white">
                <h1 className="font-primary text-3xl font-extrabold text-white dark:text-slate-800 sm:text-4xl md:text-5xl md:leading-snug ">
                  You have subscribed. Go to our{" "}
                  <a
                    className="underline decoration-blue-400"
                    href="https://hulacorn.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Home Page{" "}
                  </a>{" "}
                  to see how our projects are going.
                </h1>
              </div>
            ) : (
              <div className="space-y-8 ">
                <div className="rounded-lg dark:bg-white">
                  <h1 className="font-primary text-3xl font-extrabold text-white  dark:text-slate-800 sm:text-4xl md:text-5xl md:leading-tight">
                    Thank you for your input
                  </h1>
                  <p className="font-secondary text-base text-white dark:text-slate-800 md:text-lg lg:text-xl">
                    To see which problem we solve first, join our mailing list
                  </p>
                </div>
                <form
                  className="font-secondary mx-auto flex w-full max-w-lg flex-shrink justify-center px-2"
                  onSubmit={handleSubmit}
                >
                  <input
                    className="w-2/3 rounded-l-lg border-r-0 border-blue-400 px-4 first-letter:border focus:outline-none focus:ring-1 focus:ring-blue-400"
                    type="email"
                    required
                    placeholder="Your email here"
                    onChange={(e) => handleOnChange(e.target.value)}
                  />

                  <button
                    type="submit"
                    className="rounded-r-lg border border-transparent bg-blue-400 py-3 px-4 text-sm font-semibold text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 
            focus-visible:ring-blue-400 focus-visible:ring-offset-2 dark:text-slate-800 sm:text-base"
                  >
                    Subscribe
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-700 opacity-30" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="my-8 inline-block w-full max-w-fit  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-white">
                <Dialog.Title
                  as="h3"
                  className="text-center  text-lg font-medium leading-6 text-gray-900"
                >
                  Adding you to our mailing list
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-center text-sm text-gray-500"></p>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
