import React from "react";
export default function Loading() {
  return (
    <div className="grid h-full w-full grid-cols-1 items-center justify-center">
      <div className="flex items-center justify-center space-x-1 text-sm text-gray-700">
        <svg
          className="svg-container"
          height="100"
          width="100"
          viewBox="0 0 100 100"
        >
          <circle className="loader-svg bg" cx="50" cy="50" r="45"></circle>
          <circle
            className="loader-svg animate"
            cx="50"
            cy="50"
            r="45"
          ></circle>
        </svg>

        <div>
          <p className="text-2xl text-black dark:text-white">Loading ...</p>
        </div>
      </div>
    </div>
  );
}
