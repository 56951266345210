import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import config from "./config";
import { BrowserRouter as Router } from "react-router-dom";
// import { Provider } from "react-redux";
import { datadogRum } from "@datadog/browser-rum";
Amplify.configure({
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
  },
  API: {
    endpoints: [
      {
        name: "votes",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
      },
    ],
  },
});

datadogRum.init({
  clientToken: "pub25b524fecba34ad1bb46d1245161ce2d",
  applicationId: "fa09ef91-4063-4cdf-8fb1-6c7da09fb183",
  site: "datadoghq.eu",
  service: "votes.hulacorn.com",
  // Specify a version number to identify the deployed version of your application in Datadog
  version: "1.0.0",
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: "mask-user-input",
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
