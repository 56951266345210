import React, { useState } from "react";
import { CheckIcon } from "@heroicons/react/outline";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function Candidate({ problems }) {
  const navigate = useNavigate();
  const [, setLoading] = useState(false);
  const [localProblems, setProblems] = useState(problems);
  let theArray = ["Show All"];

  problems.tiers.map((problem) => {
    problem.tags.map((tag) => {
      if (theArray.indexOf(tag) === -1) {
        theArray.push(tag);
      }
    });
  });

  let [isOpen, setIsOpen] = useState(false);

  function filterProblems(myProblem) {
    let flteredProblems = problems.tiers.filter((problem) => {
      return problem.tags.includes(myProblem);
    });

    if (myProblem === "Show All") {
      flteredProblems = problems.tiers;
    }

    setProblems({ tiers: flteredProblems });
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  async function submitVote(problem) {
    setLoading(true);
    openModal();
    try {
      await createVote({ problem });
      navigate("voted");
    } catch (e) {
      console.log(e);
    }
  }

  function createVote(vote) {
    return API.post("votes", `/votes/${vote.problem}`, {
      body: "",
    });
  }

  return (
    <>
      <div className="flex flex-row flex-wrap justify-center space-x-1 space-y-1">
        {theArray.map((tag) => (
          <button
            type="button"
            onClick={() => filterProblems(tag)}
            className="inline-flex items-center rounded border border-transparent bg-blue-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            {tag}
          </button>
        ))}
      </div>
      <div className="grid h-full grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {localProblems.tiers.map((problem) => (
          <div key={problem.key} className="items-center justify-center ">
            <div>
              <div
                key={problem.key}
                className="rounded-xl border-2 border-black pt-6 dark:border-white"
              >
                <div className="space-y-6 space-x-6">
                  <p className="pl-2 pr-2 text-center text-4xl font-extrabold text-gray-900 dark:text-white">
                    {problem.title}
                  </p>
                  <p className="pl-2 pr-2 text-center text-xl text-gray-900 dark:text-white">
                    {problem.description}
                  </p>
                </div>

                <div className="mt-6 space-y-4 px-6 pt-3 pb-4">
                  {problem.features.map((feature) => (
                    <li key={feature.key} className="flex space-x-3">
                      <CheckIcon
                        className="h-5 w-5 flex-shrink-0 text-gray-800 dark:text-white"
                        aria-hidden="true"
                      />
                      <span className="text-md text-gray-800 dark:text-white">
                        {feature.desc}
                      </span>
                    </li>
                  ))}
                </div>
                <div className="flex flex-row justify-center">
                  <div className="rounded-lg pl-4 pr-4 pb-4 ">
                    <button
                      type="button"
                      className="text-md dark:text-blue rounded-lg border-2 border-white px-6 py-2  text-blue-400 transition-colors duration-300"
                      onClick={() => {
                        submitVote(problem.key);
                      }}
                    >
                      I have this Problem
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-700 opacity-30" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="my-8 inline-block w-full max-w-fit  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-center  text-lg font-medium leading-6 text-gray-900"
                >
                  Processing your vote
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-center text-sm text-gray-500"></p>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
