import "./App.css";
import React, { useState, useEffect } from "react";
import { Breakpoint, Loading } from "./component/components";
import Candidate from "./pages/candidate";
import Voted from "./pages/voted";
import { Routes, Route } from "react-router-dom";
import { Switch } from "@headlessui/react";
import { ThemeProvider, ThemeContext } from "./component/themeContext";
import { API } from "aws-amplify";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Home() {
  const [isBreakPointOn] = useState(false);
  const [visible] = useState(true);

  const { theme, setTheme } = React.useContext(ThemeContext);
  let ThemeSate = theme === "light" ? false : true;
  const [enabled, setEnabled] = useState(ThemeSate);

  function SetDarkMode(e) {
    console.log(e);
    setEnabled(!enabled);
    setTheme(e ? "dark" : "light");

    if (e) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }

  let myvotes = async function () {
    return await API.get("votes", `/votes`, {
      body: "",
    });
  };

  let [problems, setProblems] = useState(null);

  useEffect(() => {
    myvotes().then((res) => {
      let tiers = res.data;
      tiers = tiers.sort(() => Math.random() - 0.5);
      let myproblems = { tiers: tiers };
      setProblems(myproblems);
    });
  }, []);

  return (
    <>
      <div className="grid justify-items-end p-4">
        <Switch
          checked={enabled}
          onChange={SetDarkMode}
          className={classNames(
            enabled ? "bg-gray-600" : "bg-yellow-500",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-teal-700 focus:ring-offset-2"
          )}
        >
          <span className="sr-only">Use setting</span>
          <span
            className={classNames(
              enabled ? "translate-x-5" : "translate-x-0",
              "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          >
            <span
              className={classNames(
                enabled
                  ? "opacity-0 duration-100 ease-out"
                  : "opacity-100 duration-200 ease-in",
                "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
              )}
              aria-hidden="true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
            </span>
            <span
              className={classNames(
                enabled
                  ? "opacity-100 duration-200 ease-in"
                  : "opacity-0 duration-100 ease-out",
                "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
              )}
              aria-hidden="true"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
              </svg>
            </span>
          </span>
        </Switch>
      </div>
      <div className="mx-auto max-w-7xl space-y-10 bg-white py-24 px-4 dark:bg-slate-800 sm:px-6 lg:px-8">
        <h2 className="link link-underline link-underline-slate-800 text-center text-5xl font-extrabold text-slate-800 dark:text-white sm:leading-none sm:tracking-tight">
          Vote for the problem you want solved.
        </h2>
        <p className="mt-6 text-center text-xl text-gray-600 dark:text-white ">
          Want to see your problem here? tweet{" "}
          <a
            href="https://twitter.com/intent/tweet?padraigobrien=Hulacorn&ref_src=votes.hulacorn.com"
            class="twitter-mention-button text-blue-600"
            data-show-count="false"
          >
            @padraigobrien
          </a>
          {""} or email padraig@hulacorn.com and we will get it added
          <script
            async
            src="https://platform.twitter.com/widgets.js"
            charset="utf-8"
          ></script>
        </p>
        {problems ? <Candidate problems={problems} /> : <Loading />}
        {isBreakPointOn && <Breakpoint />}
      </div>
    </>
  );
}

export default function App() {
  return (
    <>
      <ThemeProvider>
        <div className="bg-white dark:bg-slate-800">
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/voted" element={<Voted />}></Route>
          </Routes>
        </div>
      </ThemeProvider>
    </>
  );
}
